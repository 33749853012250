import { ADD_MESSAGE, REMOVE_MESSAGE } from "../actionTypes/message";

export const addMessage = ({ type, title, description, link, linkText }) => ({
  type: ADD_MESSAGE,
  payload: {
    type,
    title,
    description,
    link,
    linkText,
  },
});

export const removeMessage = () => ({
  type: REMOVE_MESSAGE,
});

export default {
  addMessage,
  removeMessage,
};
