import {
  SET_USER,
  RESET_USER,
  UPDATE_USER_INFO,
  UPDATE_ACCOUNT_INFO,
  UPDATE_ISCOMPANY,
  UPDATE_SUBSCRIPTION,
} from "../actionTypes/user";

export const setUser = ({
  token,
  first_name,
  last_name,
  email,
  company,
  tax_number,
  street,
  postal_code,
  city,
  account_holder,
  iban,
  is_company,
  unsubscribed_at,
}) => {
  return {
    type: SET_USER,
    payload: {
      token,
      first_name,
      last_name,
      email,
      company,
      tax_number,
      street,
      postal_code,
      city,
      account_holder,
      iban,
      is_company,
      unsubscribed_at,
    },
  };
};

export const resetUser = () => {
  return {
    type: RESET_USER,
  };
};

export const updateUserInfo = ({
  first_name,
  last_name,
  company,
  tax_number,
  street,
  postal_code,
  city,
}) => {
  return {
    type: UPDATE_USER_INFO,
    payload: {
      first_name,
      last_name,
      tax_number,
      company,
      street,
      postal_code,
      city,
    },
  };
};

export const updateAccountInfo = ({ account_holder, iban }) => {
  return {
    type: UPDATE_ACCOUNT_INFO,
    payload: {
      account_holder,
      iban,
    },
  };
};

export const updateCompanyInfo = ({ is_company }) => {
  return {
    type: UPDATE_ISCOMPANY,
    payload: {
      is_company,
    },
  };
};

export const updateSubscription = ({ unsubscribed_at, deleted_at }) => {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload: {
      unsubscribed_at,
      deleted_at,
    },
  };
};
export default {
  setUser,
  resetUser,
  updateUserInfo,
  updateAccountInfo,
  updateCompanyInfo,
  updateSubscription,
};
