import store from "../redux/store";
import actions from "../redux/actions";
import { BASE_URL } from "./config";
import { getHeader } from "./auth";

export const isBrowser = () => typeof window !== "undefined";

export {
  handleLogin,
  updateUserProfile,
  updateAccountInfo,
  updateUserPassword,
  isLoggedIn,
  logout,
  handleRegister,
  forgotPassword,
  resetPassword,
  getHeader,
} from "./auth";

const getToken = () => {
  return store.getState()?.user?.token;
};
export const getPrices = async () => {
  const resp = await fetch(
    `${BASE_URL}/thg/prices/${process.env.GATSBY_COMPANY}`,
    {
      headers: getHeader(),
    }
  );
  const response = await resp.json();
  store.dispatch(actions.prices.setPrices(response?.data || []));
  return true;
};

export const getQuotes = async (unauthorizedCallback) => {
  const resp = await fetch(`${BASE_URL}/thg/quote-years`, {
    headers: getHeader(true),
  });
  const response = await resp.json();
  if (response.error_code === 401) {
    store.dispatch(actions.user.resetUser());
    if (unauthorizedCallback) {
      unauthorizedCallback();
    }

    return null;
  } else {
    store.dispatch(actions.quotes.setQuotes(response?.data || []));

    return true;
  }
};

export const getRenewalPrices = async () => {
  const resp = await fetch(`${BASE_URL}/thg/renew-price?type=stellantis`, {
    headers: getHeader(),
  });
  if (resp) {
    const response = await resp?.json();
    store.dispatch(actions.renewalPrices.setRenewalPrice(response.data || []));
    return true;
  }
};

export const addQuote = async (
  { frontUpload, isOther },
  unauthorizedCallback
) => {
  var data = new FormData();
  data.append("isOther", isOther);
  data.append("frontUpload", frontUpload);

  const resp = await fetch(`${BASE_URL}/thg/quotes`, {
    headers: {
      //"Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getToken()}`,
    },
    method: "POST",
    body: data,
  });
  const response = await resp?.json();

  if (response.success === true) {
    store.dispatch(
      actions.message.addMessage({
        type: "success",
        title: "Erfolg",
        link: "/authenticated/vehicles",
        linkText: "Klicken Sie hier, um die Fahrzeugliste anzuzeigen.",
        description: "Sie haben Ihr Fahrzeug erfolgreich hochgeladen.",
      })
    );
    return true;
  } else if (response.errors) {
    // store.dispatch(actions.quotes.addQuote(json?.data));
    store.dispatch(
      actions.message.addMessage({
        type: "error",
        title: "falsche Daten!",
        description: "Bitte überprüfen Sie die Daten noch einmal!",
      })
    );
  } else {
    if (response.error === "unreadable") {
      store.dispatch(
        actions.message.addMessage({
          type: "error",
          title: "Dateilesefehler!",
          description: "Die Datei kann nicht gelesen werden.",
        })
      );
    } else if (response.error === "wrong_vehicle") {
      store.dispatch(
        actions.message.addMessage({
          type: "error",
          title: "Ungültiges Fahrzeug!",
          description:
            "Das hochgeladene Fahrzeug ist kein Elektrofahrzeug und daher nicht förderfähig.",
        })
      );
    } else if (response.error === "server") {
      store.dispatch(
        actions.message.addMessage({
          type: "error",
          title: "Serverfehler!",
          description:
            "Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut oder kontaktieren Sie unseren Support.",
        })
      );
    } else {
      store.dispatch(actions.user.resetUser());
      if (unauthorizedCallback) {
        unauthorizedCallback();
      }
    }
    return null;
  }
};
