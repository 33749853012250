import quotes from "./quotes";
import user from "./user";
import message from "./message";
import renewQuotes from "./renewQuotes";
import renewalPrices from "./renewalPrices";
import prices from "./prices";

const actions = {
  quotes,
  user,
  message,
  renewQuotes,
  renewalPrices,
  prices,
};

export default actions;
